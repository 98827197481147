<template>
  <div class="sign-up">
    <div class="header">
      <img src="../../assets/imgs/logo.png" style="height: 100px" alt="" />
      <p>课程报名</p>
    </div>
    <div class="container">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="form.gender" placeholder="请选择性别">
            <el-option label="男" value="m"></el-option>
            <el-option label="女" value="f"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCard">
          <el-input
            v-model="form.idCard"
            placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="orgTitle">
          <el-input
            v-model="form.orgTitle"
            placeholder="请输入所属部门"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <div
          class="gradient-background"
          @click="confirm"
          style="
            line-height: 40px;
            margin-top: 30px;
            width: 100px;
            display: inline-block;
          "
        >
          确认
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sign-up",
  data() {
    return {
      form: {
        name: "",
        email: "",
        idCard: "",
        mobile: "",
        orgTitle: "",
        gender: "",
        idCardFImage: "",
        idCardBImage: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        idCard: [{ required: true, message: "请输入身份证", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur"],
          },
        ],
        orgTitle: [
          { required: true, message: "请输入所属部门", trigger: "blur" },
        ],
      },
      uploadData: {
        type: "idf",
      },
    };
  },
  methods: {
    handleAvatarSuccess(file, type) {
      if (file.response) {
        this.information[type] = file.response.path;
        this.dialogInfo[type] = URL.createObjectURL(file.raw);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("图片只能是 jpg jpeg png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    selectImg(type) {
      this.uploadData.type = type;
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$https.post("/api/book", this.form).then((res) => {
            if (res.success) {
              this.$message.success("报名成功");
              window.location.reload();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sign-up {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  padding-top: 50px;
  .header {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #677897;
    font-weight: bold;
  }
  .container {
    padding: 20px 30%;
  }
  .footer {
    text-align: center;
  }
}
</style>
